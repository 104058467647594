import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Inject,
	Input,
	Optional,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { IconsRegistry } from './icons-registry.service';

@Component({
	selector: 'eui-icons',
	template: ` <ng-content></ng-content> `,
	styleUrls: ['./icons.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconsComponent {
	private wrappedSize = 24;

	@Input() set size(value: number) {
		this.wrappedSize = value;
		if (this.svgIcon) {
			this.svgChangeSize();
		}
	}

	get size() {
		return this.wrappedSize;
	}

	private svgIcon!: SVGElement;

	@Input()
	set name(iconName: string) {
		if (this.svgIcon) {
			this.hostElement.nativeElement.removeChild(this.svgIcon);
		}

		const svgData = this.iconRegistry.getIcon(iconName);

		if (svgData) {
			this.svgIcon = this.svgElementFromString(svgData);
			this.hostElement.nativeElement.appendChild(this.svgIcon);

			this.svgChangeSize();
		}
	}

	constructor(
		private hostElement: ElementRef,
		private iconRegistry: IconsRegistry,
		@Optional() @Inject(DOCUMENT) private document: Document,
	) {}

	private svgElementFromString(svgContent: string): SVGElement {
		const div = this.document.createElement('DIV');
		div.innerHTML = svgContent;
		return (
			div.querySelector('svg') ||
			this.document.createElementNS('http://www.w3.org/2000/svg', 'path')
		);
	}

	private svgChangeSize() {
		this.svgIcon.setAttribute('width', `${this.size}px`);
		this.svgIcon.setAttribute('height', `${this.size}px`);
	}
}
