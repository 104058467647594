import {
	Directive,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	Output,
} from '@angular/core';

@Directive({
	selector: '[euiSideMenuItem]',
})
export class SideMenuItemDirective {
	@HostBinding('class.eui-side-menu__item') menuItem = true;

	@HostBinding('class.eui-side-menu__item-selected') isSelected = false;

	@Input()
	name = '';

	@Output()
	selected: EventEmitter<string> = new EventEmitter<string>();

	@HostListener('click', ['$event'])
	select() {
		this.selected.emit(this.name);
	}
}
