import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BadgeColors } from './types/badge.interface';

@Component({
	selector: 'eui-badge',
	template: `<div
		class="eui-badge"
		[ngClass]="{
			'eui-badge__only-icon': onlyIcon,
			'eui-badge__blue': color === 'blue',
			'eui-badge__green': color === 'green',
			'eui-badge__yellow': color === 'yellow',
			'eui-badge__red': color === 'red',
			'eui-badge__purple': color === 'purple',
			'eui-badge__orange': color === 'orange',
			'eui-badge__magenta': color === 'magenta',
			'eui-badge__cyan': color === 'cyan',
			'eui-badge__grey': color === 'grey',
		}">
		<ng-content></ng-content>
	</div> `,
	styleUrls: ['./badge.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
	@Input() color!: BadgeColors;
	@Input() onlyIcon = false;
}
