import { Injectable } from '@angular/core';

import { EuiIcon } from './types/icon.interface';

@Injectable({
	providedIn: 'root',
})
export class IconsRegistry {
	private registry = new Map<string, string>();

	public registerIcons(icons: EuiIcon[]): void {
		icons.forEach((icon: EuiIcon) => this.registry.set(icon.name, icon.data));
	}

	public getIcon(iconName: string): string | undefined {
		if (!this.registry.has(iconName)) {
			console.warn(
				`We could not find icon with the name ${iconName}, did you add it to the Icon registry?`,
			);
		}
		return this.registry.get(iconName);
	}
}
