<ng-container
	[ngTemplateOutletContext]="{ $implicit: menu }"
	[ngTemplateOutlet]="menuTemplateRef"></ng-container>

<ng-template #menuTemplateRef let-menuItems>
	<ul class="eui-side-menu">
		<ng-container *ngFor="let menuItem of menuItems; trackBy: trackByFn">
			<ng-container *ngIf="!menuItem.children; else subMenuTemplateRef">
				<li>
					<button
						class="eui-side-menu__item"
						[class.eui-side-menu__item-selected]="
							isSelected(menuItem) && !menuItem.externalLink
						"
						euiSideMenuItem
						(click)="menuItemClickHandler(menuItem)"
						[disabled]="menuItem?.disabled">
						<span class="eui-side-menu__item-title">
							{{ menuItem.title }}

							<ng-container *ngIf="menuItem.count">
								<eui-badge [color]="'blue'">
									{{ menuItem.count | number: '' : 'ru' }}
								</eui-badge>
							</ng-container>
						</span>
						<span class="eui-side-menu__item-chevron">
							<ng-container *ngIf="menuItem.externalLink">
								<eui-icons name="external-link" [size]="20"></eui-icons>
							</ng-container>
						</span>
					</button>
				</li>
			</ng-container>

			<ng-template #subMenuTemplateRef>
				<li>
					<button
						class="eui-side-menu__item"
						[class.eui-side-menu__item-selected]="
							isSelected(menuItem) && !menuItem.externalLink
						"
						euiSideMenuItem
						(click)="menuItemClickHandler(menuItem)"
						[disabled]="menuItem?.disabled">
						<span class="eui-side-menu__item-title">
							{{ menuItem.title }}
							<ng-container *ngIf="menuItem.externalLink">
								<eui-icons name="external-link" [size]="20"></eui-icons>
							</ng-container>

							<ng-container *ngIf="menuItem.count">
								<eui-badge [color]="'blue'">
									{{ menuItem.count | number: '' : 'ru' }}
								</eui-badge>
							</ng-container>
						</span>
						<span class="eui-side-menu__item-chevron">
							<ng-container *ngIf="isExpanded(menuItem); else expandMoreIcon">
								<eui-icons name="chevron-up" [size]="20"></eui-icons>
							</ng-container>

							<ng-template #expandMoreIcon>
								<eui-icons name="chevron-down" [size]="20"></eui-icons>
							</ng-template>
						</span>
					</button>

					<ng-container
						*ngIf="isExpanded(menuItem)"
						[ngTemplateOutletContext]="{ $implicit: menuItem.children }"
						[ngTemplateOutlet]="menuTemplateRef"></ng-container>
				</li>
			</ng-template>
		</ng-container>
	</ul>
</ng-template>
