import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	QueryList,
	SimpleChanges,
	ViewChildren,
} from '@angular/core';

import { SideMenu } from './types/side-menu.interface';
import { SideMenuItemDirective } from './side-menu-item.directive';

@Component({
	selector: 'eui-side-menu',
	templateUrl: 'side-menu.component.html',
	styleUrls: ['./side-menu.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuComponent implements OnChanges {
	@Input() menu: SideMenu[] = [];
	@Input() selectedMenu!: SideMenu | null;
	@Input() expandedMenu!: SideMenu | null;
	@Output() menuItemClick = new EventEmitter();

	@ViewChildren(SideMenuItemDirective)
	private menuItemButtons!: QueryList<SideMenuItemDirective>;

	expandedMenuItem: { [key: string]: boolean } = {};
	selectedMenuItem: SideMenu | null = null;

	constructor(private cdr: ChangeDetectorRef) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['selectedMenu'] && changes['selectedMenu'].currentValue) {
			this.select(changes['selectedMenu'].currentValue);
		}

		if (changes['expandedMenu'] && changes['expandedMenu'].currentValue) {
			this.expand(changes['expandedMenu'].currentValue);
		}
	}

	trackByFn(index: number, item: SideMenu) {
		return item;
	}

	menuItemClickHandler(menuItem: SideMenu) {
		this.menuItemClick.emit(menuItem);

		if (menuItem.externalLink) {
			window.open(menuItem.externalLink, '_blank');
			return;
		}

		if (menuItem.children) {
			this.expand(menuItem);
		} else {
			this.select(menuItem);
		}
	}

	isExpanded(menuItem: SideMenu) {
		return !!this.expandedMenuItem[menuItem.name];
	}

	isSelected(menuItem: SideMenu) {
		return this.selectedMenuItem?.name === menuItem.name;
	}

	select(menuItem: SideMenu) {
		if (this.menuItemButtons) {
			this.menuItemButtons.forEach(
				(button) => (button.isSelected = button.name === menuItem.name),
			);
		}
		this.selectedMenuItem = menuItem;
		this.cdr.markForCheck();
	}

	private expand(menuItem: SideMenu) {
		this.expandedMenuItem[menuItem.name] = !this.isExpanded(menuItem);
		this.cdr.markForCheck();
	}
}
